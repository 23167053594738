import React, { useState, useEffect } from "react";
import Select from 'react-select';

import "../../../../assets/stylesheets/funnel/components/partsSearch/partsSearchBox.scss";
import SearchErrorMsg from "./SearchErrorMsg";
import { vinLength } from "../../constants/SiteConstants";

const searchTypes = {
  partNo:'Part Number',
  vinLPSearch: 'VIN / Licence Plate'
}

function PartsSearchBox(props) { 
  const [state, setState] = useState({
    searchedText: "",
    selectedTypes: searchTypes.partNo,
    enableDropdown: false,
    validatingLicense: false,
    disableCta: false,
    searchInputError: false,
    showNotSupportedBrandError: false,
    types: [
      { label: searchTypes.partNo, value: searchTypes.partNo },
      { label: searchTypes.vinLPSearch, value: searchTypes.vinLPSearch }
    ],
  });

  const [error, setError] = useState({ isError: false, errorMsg: '' });

  const { partsSearchData, actions } = props;
  const translationsData = {
    searchByVinOePlaceholder: "Enter VIN / License Plate / Part Number",
    finNotValidText: "Sorry, We dont support this vehicle currently.",
    finValidatingMessage: "Checking make and model ...",
    chooseBrandText: "Select brand",
    searchOptionCtaText: "Search",
    brandNotValidText: "Sorry, We dont support this brand currently, please try a different search.",
    somethingWrong: "License plate not found. Please try again later !"
    
  };

  // Post License Plate Decoding call checks
  useEffect(() => {
    if (
      props.partsSearchData.licencePlateIdentified &&
      state.validatingLicense
    ) {
      if (
        props.partsSearchData.licenseDecodedData &&
        props.partsSearchData.licenseDecodedData.vin
      ) {
        if(  props.partsSearchData.licenseDecodedData.brand && isBrandAllowed( props.partsSearchData.licenseDecodedData.brand ) ){
          let finNumber = props.partsSearchData.licenseDecodedData.vin;
          setPartsSearchDataAndRedirect(finNumber);
        }
        else{
          setState({ ...state,
            showNotSupportedBrandError: true,
            searchInputError: true

          });
        }
      } else {
        showBrandSelectionDropdownForOENumber();
      }
    }
  }, [props.partsSearchData.licencePlateIdentified]);

  function isBrandAllowed( brand ){
    if( !brand ){
      return true;
    }
    return  ["LAND ROVER", "JAGUAR"].indexOf( brand.toUpperCase() ) == -1;
  }

  function selectType( type) {
    setState({ ...state, selectedTypes: type, searchInputError: false });
    setError({isError: false, errorMsg: ''});
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      searchCtaClick();
    }
  }

  function onInputChange(evt) {
    let searchedText = evt.target.value.trim();
    setState({
      ...state,
      searchedText: searchedText,
      selectedTypes: searchTypes.partNo,
      enableDropdown: false,
      searchInputError: false,
      showNotSupportedBrandError: false,
    });
    setError({isError: false, errorMsg: ''});

    actions.partsSearchActions.updateSearchInput(searchedText);

    if (
      searchedText &&
      searchedText.length > 2 &&
      (!partsSearchData.searchedText ||
        (partsSearchData.searchedText &&
          partsSearchData.searchedText.substring(0, 3) !==
            searchedText.substring(0, 3)))
    ) {
      actions.partsSearchActions.tryIdentifyingBrandName(searchedText);
    }
  }

  function getParsedSearchText() {
    let searchedText = state.searchedText;

    // Removing Spaces
    if (searchedText) {
      searchedText = searchedText.split(" ").join("").toUpperCase();
    }
    return searchedText;
  }

  function getFinNumber() {
    let finNumber = getParsedSearchText();

    if (
      props.partsSearchData.licencePlateIdentified &&
      !state.validatingLicense &&
      partsSearchData.licenseDecodedData &&
      partsSearchData.licenseDecodedData.vin
    ) {
      finNumber = partsSearchData.licenseDecodedData.vin;
    }
    return finNumber;
  }

  function isValidFin() {
    let finNumber = getFinNumber();
    return finNumber && finNumber.length == vinLength;
  }

  function isBrandSelectedForOENumber() {
    return state.enableDropdown && state.selectedTypes;
  }

  function validateInputAsLicensePlate(searchedText) {
    setState({
      ...state,
      validatingLicense: true,
    });
    actions.partsSearchActions.tryFetchingFinNumberFromLicencePlate(
      searchedText
    );
  }


  function showBrandSelectionDropdownForOENumber() {
    setState({
      ...state,
      enableDropdown: true,
      validatingLicense: false,
    });
  }

  function getSearchObj() {
    let searchObj = {
      finNumber: "",
      selectedTypes: searchTypes.partNo,
      searchedText: getParsedSearchText(),
      operationalList: [],
      oeNumber: "",
    };
    if (state.selectedTypes) {
      searchObj.selectedTypes = state.selectedTypes || searchTypes.partNo;
      searchObj.oeNumber = getParsedSearchText();
    }

    if ( isValidFin()) {
      searchObj.finNumber = getFinNumber();
    }

    return searchObj;
  }

  function startFetchingPartsForOENumber() {
    let partsSearchObj = getSearchObj();

    actions.partsSearchActions.updateSearchObj(partsSearchObj);

    actions.partsActions.tryFetchingPartListFromOEAsync([
      {
        oe_number: partsSearchObj.oeNumber,
        part_description: "",
      },
    ]);
    setState({
      ...state,
      disableCta: true,
    });
  }

  function validateFin(finNumber) {
    let partsSearchObj = getSearchObj();

    partsSearchObj.finNumber = finNumber || partsSearchObj.finNumber;

    actions.partsSearchActions.updateSearchObj(partsSearchObj);
    actions.partsSearchActions.tryValidatingFinAndFetchingPartListAsync(
      finNumber
    );
    actions.partsActions.showPartsResultPage();

    setState({
      ...state,
      disableCta: true,
    });
  }

  function setPartsSearchDataAndRedirect(finNumber) {
    let partsSearchObj = getSearchObj();

    partsSearchObj.finNumber = finNumber || partsSearchObj.finNumber;

    actions.partsSearchActions.updateSearchObj(partsSearchObj);
  }

  function searchCtaClick() {
    let searchedText = getParsedSearchText();

    let partsSearchData = props.partsSearchData;

    if (searchedText && searchedText.length > 2) {

      if(searchedText.length === 17 && state.selectedTypes === searchTypes.partNo){
        setError({isError: true, errorMsg: 'Please select vin no'})
      }

      // Case 1: check for oe number between 4-5
      if(!isBrandSelectedForOENumber() && state.selectedTypes === searchTypes.partNo ){
        showBrandSelectionDropdownForOENumber();
      }

      // Case 2: check for license plate
      else if (!partsSearchData.licencePlateIdentified && state.selectedTypes === searchTypes.vinLPSearch  && state.searchedText.length !== 17) {
        validateInputAsLicensePlate(searchedText);
      }
      // Case 3 : fin entered and not validated
      else if (state.selectedTypes === searchTypes.vinLPSearch && isValidFin()) {
        if( partsSearchData.identifiedVehicleInfo){
          // setPartsSearchDataAndRedirect(searchedText);
          validateFin( searchedText );
        } 
        else{
          setState({ ...state,
            showNotSupportedBrandError: true,
            searchInputError: true
          })
        }
        
      }

      // Case 4: OE search and no Brand Selected
      else if (!isBrandSelectedForOENumber()) {
        showBrandSelectionDropdownForOENumber();
      }
      // Case 5: OE search with Brand Selection
      else if (state.selectedTypes === searchTypes.partNo && state.searchedText.length !== 17) {
        startFetchingPartsForOENumber();
      }
    } else {
      setState({
        ...state,
        searchInputError: true,
      });
    }
  }

  return (
    <div className="parts-search-box-container md-pb16">
      <div
        className={
          "decoded-brand md-c797979 md-text14 md-pb4 " +
          (state.searchedText.length > 2 &&
          partsSearchData.identifiedVehicleInfo &&
          partsSearchData.identifiedVehicleInfo.make
            ? " show-brand-name"
            : "")
        }
      >
        {partsSearchData.identifiedVehicleInfo.make || "-"}
      </div>
      <div
        className={
          "search-box-container md-text16" + (state.enableDropdown ? " show-brands-dd" : "")
        }
      >
        <div className="search-box ibm ">
          <input
            id="search-input"
            className={
              "search-input db md-ls03 md-lh14 md-text16" +
              (state.searchInputError ? " error" : "")
            }
            type="text"
            maxLength="50"
            placeholder={translationsData.searchByVinOePlaceholder}
            onChange={onInputChange}
            onKeyPress={handleKeyPress}
            value={state.searchedText}
          ></input>
          {/* <img src="https://d3brnpc5nhvc9v.cloudfront.net/gp24-images/gp24-icons/question_mark.png" className="question_icon" />  */}
          {state.searchedText.length > 2 &&
            partsSearchData.identifiedVehicleInfo &&
            partsSearchData.identifiedVehicleInfo.gt_motive_support && (
              <span className="md-text14 fin-helper md-c797979">
                {getParsedSearchText().length + " / 17"}
              </span>
            )}
        </div>

        { (
          <div
            className='brand-dropdown-container  cp ibm tl'>
            {state.enableDropdown &&
            <Select
              options={state.types}
              onChange={type=>selectType(type.value)}
              className='md-text13 md-f300 md-c262626 brand-placeholder'
              placeholder={state.selectedTypes}
            />}
          </div>
        )}
        <div
          className="cp search-cta ibm tc md-ls03 orange-hover"
          onClick={(e) => searchCtaClick()}
        >
          {partsSearchData.showInputLoader ? (
            <span className="sprite white-loader"></span>
          ) : (
            <span className="search-text ibm md-cffffff">
              {translationsData.searchOptionCtaText}
            </span>
          )}
        </div>
      { state.searchInputError ? <SearchErrorMsg ddEnable={state.enableDropdown} /> : '' }
      </div>
      { state.showNotSupportedBrandError && (
        <div className="md-text14 fin-error ibm md-pt4 md-cE82442">{ translationsData.brandNotValidText }</div>
        )
      }
        <div className="licence_plate_error">
          { partsSearchData?.licenseDecodedData?.is_error ? partsSearchData?.licenseDecodedData?.message : '' }
        </div>
        <div className="licence_plate_error">
          { partsSearchData.licencePlateError && translationsData.somethingWrong }
        </div>
        <div className="mr_100 licence_plate_error">
          { error?.isError ? error.errorMsg : '' }
        </div>
    </div>
  );
}
export default PartsSearchBox;
